import { template as template_bd6fa1f1fcf24bc5b7ce31c94480290a } from "@ember/template-compiler";
import Component from "@glimmer/component";
import DButton from "discourse/components/d-button";
export default class FKSubmit extends Component {
    get label() {
        return this.args.label ?? "submit";
    }
    static{
        template_bd6fa1f1fcf24bc5b7ce31c94480290a(`
    <DButton
      @label={{this.label}}
      @action={{@onSubmit}}
      @forwardEvent="true"
      class="btn-primary form-kit__button"
      type="submit"
      @isLoading={{@isLoading}}
      ...attributes
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
