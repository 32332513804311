import { template as template_aa14d749457e4d579d3a9e38ec9e8e3e } from "@ember/template-compiler";
const FKLabel = template_aa14d749457e4d579d3a9e38ec9e8e3e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
