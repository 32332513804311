import { template as template_dca383c7f17e4c0b890f9ec491a15d31 } from "@ember/template-compiler";
const SidebarSectionMessage = template_dca383c7f17e4c0b890f9ec491a15d31(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
